import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/user/filter/1`, { userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/user/all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompaniesAll() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/company/all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
